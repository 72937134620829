<!--供应商注册-->
<template>
  <div class="box">
    <div class="nav_top">
      <div class="top">
        <top-nav></top-nav>
      </div>
      <div class="text">
        <div class="first">绿水青山就是金山银山</div>
      </div>
    </div>
    <div class="content">
      <div class="title">供应商注册</div>
      <div class="line"></div>
      <div class="register" v-show="status == 0">
        <div class="top">
          <div class="img">
            <img src="../assets/supplier/3.png" width="100%" height="100%" />
          </div>
          <div class="text">
            为提升您的注册审核成功率，请准确填写信息；审核通过后您可以登录账户
          </div>
        </div>
        <el-form ref="form" :model="sizeForm" label-width="80px" size="mini">
          <div class="box_flex">
            <div class="drop_down">
              <el-form-item label="公司名称:">
                <input
                  type="text"
                  class="input_box2"
                  placeholder="请输入公司名称"
                  v-model="sizeForm.name"
                />
              </el-form-item>
            </div>
            <div class="drop_down2">
              <div class="title2">所在地：</div>
              <div class="input_box" @click="changeShow">
                <div class="input_title">
                  <el-cascader
                    placeholder="请选择所在地"
                    :options="regionList"
                    :props="optionProps"
                    v-model="sizeForm.region"
                    filterable
                    @change="changeRegion"
                  ></el-cascader>
                </div>
              </div>
            </div>
          </div>
          <div class="box_flex2">
            <el-form-item label="营业执照编号:">
              <input
                class="input_box"
                placeholder="请输入营业执照编号"
                v-model="sizeForm.businessLicenseNo"
              />
            </el-form-item>
          </div>
          <div class="box_flex2">
            <el-form-item label="公司标志:">
              <div class="up_img">
                <div class="left" v-if="!sizeForm.signPath">
                  <img src="../assets/supplier/1.png" />
                </div>
                <div class="left2" v-else>
                  <img :src="Url + sizeForm.signPath" width="100%" height="100%"/>
                </div>
                <div class="right">
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/common/upload'"
                    :on-success="handleAvatarSuccess"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                    :multiple="true"
                  >
                    <el-button size="small" type="primary">上传文件</el-button>
                  </el-upload>
                  <div class="word">
                    请上传贵公司的标志图片，将用于在商铺首页顶部公司名称左边进行展示，便于访问者加深对企业品牌的认知。图片大小不要超过<span
                      class="text_color"
                      >200kb</span
                    >
                    建议图片尺寸 120px*80px。支持的图片格式 jpg
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="box_flex2">
            <el-form-item label="营业执照:">
              <div class="up_img">
                <div class="left" v-if="!sizeForm.businessLicense">
                  <img src="../assets/supplier/1.png" />
                </div>
                <div class="left2" v-else>
                  <img :src="Url + sizeForm.businessLicense" width="100%" height="100%"/>
                </div>
                <div class="right">
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/common/upload'"
                    :on-success="handleAvatarSuccess2"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                    :multiple="true"
                  >
                    <el-button size="small" type="primary">上传文件</el-button>
                  </el-upload>
                  <div class="word">
                    请上传贵公司的营业执照扫描图片，用以方便信息核查人员进行审核。图片大小请勿超过<span
                      class="text_color"
                      >500kb</span
                    >
                    建议图片尺寸 120px*80px。支持的图片格式 jpg
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="box_flex2">
            <el-form-item label="企业资质证书:">
              <div class="up_img">
                <div class="left" v-if="!sizeForm.certificate">
                  <img src="../assets/supplier/1.png" />
                </div>
                <div class="left2" v-else>
                  <img :src="Url + sizeForm.certificate" width="100%" height="100%"/>
                </div>
                <div class="right">
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/common/upload'"
                    :on-success="handleAvatarSuccess4"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                    :multiple="true"
                  >
                    <el-button size="small" type="primary">上传文件</el-button>
                  </el-upload>
                  <div class="word">
                    请上传贵公司的营业执照扫描图片，用以方便信息核查人员进行审核。图片大小请勿超过<span
                      class="text_color"
                      >500kb</span
                    >
                    建议图片尺寸 120px*80px。支持的图片格式 jpg
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="box_flex2">
            <el-form-item label="公司形象图或视频封面图:">
              <div class="up_img">
                <div class="left" v-if="!sizeForm.imageMap">
                  <img src="../assets/supplier/1.png" />
                </div>
                <div class="left2" v-else>
                  <img :src="Url + sizeForm.imageMap" width="100%" height="100%"/>
                </div>
                <div class="right">
                  <el-upload
                    class="upload-demo"
                    :action="Url + '/common/upload'"
                    :on-success="handleAvatarSuccess3"
                    :before-upload="beforeAvatarUpload"
                    :show-file-list="false"
                    :multiple="true"
                  >
                    <el-button size="small" type="primary">上传文件</el-button>
                  </el-upload>
                  <div class="word2">
                    请上传贵公司的外景图片，将用于在公司商铺上进行展示，好的外景图片更有利于访问者对企业的信任。
                    若已上传公司视频，优先显示视频，此处请上传视频封面图。
                    图片大小请勿超过<span class="text_color">500kb</span
                    >，建议图片尺寸 670px*376px。支持的图片格式 jpg
                  </div>
                </div>
              </div>
            </el-form-item>
          </div>
          <div class="box_flex">
            <div class="drop_down">
              <el-form-item label="公司人数:">
                <input
                  type="text"
                  class="input_box2"
                  placeholder="请输入公司人数"
                  v-model="sizeForm.num"
                />
              </el-form-item>
            </div>
            <div class="drop_down">
              <el-form-item label="成立时间:">
                <el-date-picker
                  v-model="sizeForm.founded"
                  :editable="false"
                  type="date"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </div>
          </div>
          <div class="box_flex2">
            <el-form-item label="公司简介:">
              <textarea
                class="textarea_box"
                placeholder="请输入公司简介"
                v-model="sizeForm.companyProfile"
              ></textarea>
            </el-form-item>
          </div>
          <div class="box_flex2">
            <el-form-item label="主营产品:">
              <input
                class="input_box"
                placeholder="请输入主营产品"
                v-model="sizeForm.mainProducts"
              />
            </el-form-item>
          </div>
          <div class="box_flex">
            <div class="drop_down">
              <el-form-item label="联系人:">
                <input
                  type="text"
                  class="input_box2"
                  placeholder="请输入联系人"
                  v-model="sizeForm.contacts"
                />
              </el-form-item>
            </div>
            <div class="drop_down">
              <el-form-item label="联系电话:">
                <input
                  class="input_box2"
                  type="text"
                  placeholder="请输入联系电话"
                  v-model="sizeForm.Tel"
                />
              </el-form-item>
            </div>
          </div>
          <div class="box_flex">
            <div class="drop_down">
              <el-form-item label="邮箱:">
                <input
                  type="text"
                  class="input_box2"
                  placeholder="请输入邮箱"
                  v-model="sizeForm.mailbox"
                />
              </el-form-item>
            </div>
            <div class="drop_down">
              <el-form-item label="QQ号码:">
                <input
                  class="input_box2"
                  type="text"
                  placeholder="请输入QQ号码"
                  v-model="sizeForm.QQNumber"
                />
              </el-form-item>
            </div>
          </div>
          <div class="box_flex">
            <div class="drop_down">
              <el-form-item label="公司地址:">
                <input
                  type="text"
                  class="input_box2"
                  placeholder="请输入公司地址"
                  v-model="sizeForm.adress"
                />
              </el-form-item>
            </div>
            <div class="drop_down">
              <el-form-item label="公司传真:">
                <input
                  class="input_box2"
                  type="text"
                  placeholder="请输入公司传真"
                  v-model="sizeForm.Fax"
                />
              </el-form-item>
            </div>
          </div>
          <div class="box_flex2">
            <el-form-item label="其他:">
              <textarea
                class="textarea_box"
                placeholder="请输入其他信息"
                v-model="sizeForm.other"
              ></textarea>
            </el-form-item>
          </div>
          <div class="register_bom">
            <div class="btn1 btn" @click="clearAll">重新填写</div>
            <div class="btn2 btn" @click="submit">确认提交</div>
          </div>
        </el-form>
      </div>
      <div v-show="status == 1" class="failContent">
          <div class="left3">
        <img src="../assets/success.png" width="100%" height="100%" />
      </div>
      <div class="right3">
        <div class="title3">
          您的供应商资料审核已通过
        </div>
        <div class="btn3" @click="refill">修改填写</div>
      </div>
      </div>
      <div v-show="status == 2" class="failContent">
          <div class="left3">
        <img src="../assets/fail.png" width="100%" height="100%" />
      </div>
      <div class="right3">
        <div class="title3">
          {{feedbackInfo||'抱歉您的供应商资料暂未审核通过，还不能查看上传的资料'}}
        </div>
        <div class="btn3" @click="refill">重新填写</div>
      </div>
      </div>
    </div>
    <div class="bom">
      <bottom></bottom>
    </div>
  </div>
</template>

<script>
import topNav from "../components/topNav";
import bottom from "../components/bottom";
import { state } from "../api/api";
import { registeredSupplier } from "../api/api";
import { supplierInfo } from "../api/api";


export default {
  components: {
    topNav,
    bottom,
  },
  data() {
    return {
      // Url: "http://192.168.0.26:8075",
      Url:'http://cjsupplier.9czn.cn',
      sizeForm: {
        cityCode:"",//市编号
        provinceCode:'',//省编号
        provinceName:'',//省名称
        cityName:'',//市名称
        name: "", //公司名称
        region: null, //所在地
        businessLicenseNo: "", //营业执照编号
        signPath: "", //公司标志路径
        businessLicense: "", //营业执照路径
        certificate: "", //企业资质证书路径
        imageMap: "", //公司形象图或视频封面图路径
        num: "", //公司人数
        founded: "", //成立时间
        companyProfile: "", //公司简介
        mainProducts: "", //主营产品
        contacts: "", //联系人
        Tel: "", //联系电话
        mailbox: "", //邮箱
        QQNumber: "", //QQ号码
        adress: "", //公司地址
        Fax: "", //传真
        other: "", //其他
      },
      companyLogoList: [], //公司标志
      regionList: [], //省市区
      regionShow: false,
      status: "", //供应商状态
      optionProps: {
        value: "id",
        label: "name",
        children: "childs",
        // checkStrictly: true,
        expandTrigger: "hover",
      },
      Token:'',
      feedbackInfo:'',
    };
  },
  beforeMount() {
    this.Token = localStorage.getItem('token')
    this.regionList = JSON.parse(localStorage.getItem("province"))
  },
  mounted() {
    this.getState();
    this.getSupplierInfo()
  },
  methods: {
    async getSupplierInfo(){
      let res = await supplierInfo()
      if(res.ResultCode == 0){
        if(!res.Data){
          return
        }
        this.sizeForm.name = res.Data.username
        this.sizeForm.businessLicenseNo = res.Data.licenseNumber
        this.sizeForm.signPath = res.Data.logoUrl
        this.sizeForm.businessLicense = res.Data.licenseUrl
        this.sizeForm.certificate = res.Data.certUrl
        this.sizeForm.imageMap = res.Data.imageMap
        this.sizeForm.num = res.Data.number
        this.sizeForm.founded = res.Data.updateTime.slice(0,10)
        this.sizeForm.companyProfile = res.Data.synopsis
        this.sizeForm.mainProducts = res.Data.mainProducts
        this.sizeForm.contacts = res.Data.contact
        this.sizeForm.Tel = res.Data.phone
        this.sizeForm.mailbox = res.Data.email
        this.sizeForm.QQNumber = res.Data.qq
        this.sizeForm.adress = res.Data.address
        this.sizeForm.Fax = res.Data.fax
        this.sizeForm.other = res.Data.other
        this.sizeForm.region = []
        this.sizeForm.region[0] = res.Data.provinceCode
        this.sizeForm.region[1] = res.Data.cityCode
        console.log(this.sizeForm.region,'this.sizeForm.region')
      }else{
         this.sizeForm.region = []
      }
    },
    refill(){
      this.status = 0
    },
    changeRegion(e) {
      this.sizeForm.provinceCode = e[0]
      this.sizeForm.cityCode = e[1]
      this.regionList.forEach((tmp)=>{
        if(tmp.id == e[0]){
          this.sizeForm.provinceName = tmp.name
          tmp.childs.forEach(tmps=>{
            if(tmps.id == e[1]){
              this.sizeForm.cityName = tmps.name
            }
          })
        }
      })
    },
    //选择所在地选项
    handleNodeClick(data) {
      this.sizeForm.region = data.name;
      this.sizeForm.regionId = data.id;
      this.regionShow = !this.regionShow;
    },
    //显示所在地选项卡
    changeShow() {
      this.regionShow = !this.regionShow;
    },
    //供应商注册
    //获取供应商状态
    async getState() {
      let res = await state();
      if (res.ResultCode == 0) {
        this.status = res.Data.state;
        this.feedbackInfo = res.Data.reason
      }
    },
    //上传公司标志图片
    handleAvatarSuccess(res, file) {
      // console.log(file,'URL.createObjectURL(file.raw)')
      this.sizeForm.signPath = file.response.fileName;
    },
    //营业执照图片
    handleAvatarSuccess2(res, file) {
      // console.log(file,'URL.createObjectURL(file.raw)')
      this.sizeForm.businessLicense = file.response.fileName;
    },
    //公司形象图或视频封面图
    handleAvatarSuccess4(res, file) {
      // console.log(file,'URL.createObjectURL(file.raw)')
      this.sizeForm.certificate = file.response.fileName;
    },
    //公司形象图或视频封面图
    handleAvatarSuccess3(res, file) {
      // console.log(file,'URL.createObjectURL(file.raw)')
      this.sizeForm.imageMap = file.response.fileName;
    },
    //选择文件
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 2MB!");
      }
      return isJPG && isLt2M;
    },
    //重新填写
    clearAll() {
      this.sizeForm.cityCode = "";
      this.sizeForm.provinceCode = "";
      this.sizeForm.provinceName = "";
      this.sizeForm.cityName = "";
      this.sizeForm.name = "";
      this.sizeForm.region = "";
      this.sizeForm.regionId = "";
      this.sizeForm.businessLicenseNo = "";
      this.sizeForm.signPath = "";
      this.sizeForm.businessLicense = "";
      this.sizeForm.certificate = "";
      this.sizeForm.imageMap = "";
      this.sizeForm.founded = "";
      this.sizeForm.companyProfile = "";
      this.sizeForm.mainProducts = "";
      this.sizeForm.contacts = "";
      this.sizeForm.Tel = "";
      this.sizeForm.mailbox = "";
      this.sizeForm.QQNumber = "";
      this.sizeForm.adress = "";
      this.sizeForm.Fax = "";
      this.sizeForm.other = "";
    },
    //提交
    submit() {
      
      if (!this.sizeForm.businessLicenseNo) {
        this.$message({
          showClose: true,
          message: "营业执照编号不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.signPath) {
        this.$message({
          showClose: true,
          message: "公司标志不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.businessLicense) {
        this.$message({
          showClose: true,
          message: "营业执照不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.imageMap) {
        this.$message({
          showClose: true,
          message: "公司形象图或视频封面图不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.num) {
        this.$message({
          showClose: true,
          message: "公司人数不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.founded) {
        this.$message({
          showClose: true,
          message: "成立时间不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.mainProducts) {
        this.$message({
          showClose: true,
          message: "主营产品不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.contacts) {
        this.$message({
          showClose: true,
          message: "联系人不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.Tel) {
        this.$message({
          showClose: true,
          message: "联系电话不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.mailbox) {
        this.$message({
          showClose: true,
          message: "邮箱不能为空",
          type: "error",
        });
        return;
      }
      if (!this.sizeForm.adress) {
        this.$message({
          showClose: true,
          message: "公司地址不能为空",
          type: "error",
        });
        return;
      }
      this.getRegisteredSupplier()
    },
    //供应商注册
    async getRegisteredSupplier() {
      let that = this;
      let res = await registeredSupplier({
        cityCode: that.sizeForm.cityCode,
        provinceCode: that.sizeForm.provinceCode,
        provinceName: that.sizeForm.provinceName,
        address: that.sizeForm.adress,
        certUrl: that.sizeForm.certificate,
        cityName: that.sizeForm.cityName,
        contact: that.sizeForm.contacts,
        email: that.sizeForm.mailbox,
        established: that.sizeForm.founded,
        fax: that.sizeForm.Fax,
        imageMap: that.sizeForm.imageMap,
        licenseNumber: that.sizeForm.businessLicenseNo,
        licenseUrl: that.sizeForm.businessLicense,
        logoUrl: that.sizeForm.signPath,
        mainProducts: that.sizeForm.mainProducts,
        number: that.sizeForm.num,
        other: that.sizeForm.other,
        phone: that.sizeForm.Tel,
        qq: that.sizeForm.QQNumber,
        synopsis: that.sizeForm.companyProfile,
        username: that.sizeForm.name,
        videoUrl:''
      });
      if (res.ResultCode == 0) {
        if(!this.sizeForm.signingTime){
         this.$message({
          showClose: true,
          message: '提交成功',
          type: 'success'
        });
        this.getState()
      }
      }else{
        this.$message({
          showClose: true,
          message: res.ResultInfo,
          type: 'error'
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.box {
  width: 100%;
  .nav_top {
    width: 100%;
    height: 33.75rem;
    background-image: url("../assets/banner_mao.png");
    background-repeat: no-repeat;
    background-size: cover;
    box-sizing: border-box;

    .text {
      height: calc(100% - 100px);
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      text-align: center;
      font-size: 3.125rem;
      font-family: 'YanShiYouRanXiaoKai';

      transition: bounce-in 3s;

      .first {
        font-size: 6.875rem;
        // font-weight: bold;
        margin-bottom: 3.125rem;
      }
    }
    .top {
      width: 100%;
      height: 6.25rem;
      background-size: 100% 6.25rem;
      background-repeat: no-repeat;
    }

    h1 {
      font-size: 4.125rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      margin-top: 8.75rem;
    }

    h2 {
      font-size: 2.5rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: rgba($color: #ffffff, $alpha: 0.8);
      text-align: center;
      margin-top: 3.1875rem;
    }
  }
  .content {
    .title {
      font-size: 2rem;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #333333;
      text-align: center;
      margin-top: 6.75rem;
      margin-bottom: 1.4375rem;
    }
    .line {
      width: 4.375rem;
      height: .3125rem;
      background: #2477e4;
      margin: 0 auto;
      margin-bottom: 3.25rem;
    }
    .register {
      width: 75rem;
      // height: 1559px;
      background: #ffffff;
      border: 1.8125rem solid #f3f3f3;
      margin: 0 auto;
      margin-bottom: 9.3125rem;
      padding: 2.125rem 2.9375rem;
      box-sizing: border-box;
      .top {
        width: 100%;
        height: 3.125rem;
        background: #f0f6ff;
        border: 1px solid #2477e4;
        padding-left: 100px;
        display: flex;
        margin-bottom: 38px;
        .img {
          width: 1.25rem;
          height: 1.25rem;
          margin-top: 1rem;
        }
        .text {
          line-height: 3.125rem;
          margin-left: .9375rem;
        }
      }
      .box_flex {
        display: flex;
        justify-content: space-between;
        margin-top: 2.125rem;
        .input_box2 {
          width: 23.75rem;
          height: 2.5rem;
          background: #f4f4f4;
          border: 1px solid #eaeaea;
          padding-left: 1.75rem;
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
        .drop_down2 {
          display: flex;
          align-items: center;
          height: 2rem;
          .title2 {
            font-size: .875rem;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
          }
          .input_box {
            position: relative;
            width: 23.75rem;
            height: 2.25rem;
            // background: #eaeaea;
            border-radius: .25rem;
            display: flex;
            justify-content: space-between;
            align-items: center;
            // border: 1px solid #eaeaea;
            input {
              background: none;
              padding: .625rem;
            }
            .input_title {
              width: 100%;
            }
          
          }
        }
        .drop_down {
          width: 45%;
        }
      }
      .box_flex2 {
        margin-top: 2.125rem;
        .up_img {
          display: flex;
          .left {
            width: 7.5rem;
            height: 7.5rem;
            background: #f4f4f4;
            box-sizing: border-box;
            padding-left: 2.4375rem;
            padding-top: 2.625rem;
            img {
              width: 2.625rem;
              height: 2.25rem;
            }
          }
          .left2 {
            width: 7.5rem;
            height: 7.5rem;
            background: #f4f4f4;
            box-sizing: border-box;
          }
          .right {
            width: 80%;
            margin-left: 1rem;
            .btn {
              width: 4rem;
              height: 1.375rem;
              background: #2477e4;
              border-radius: .125rem;
              border: none;
              font-size: .625rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #ffffff;
            }
            .word {
              font-size: .75rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 1.25rem;
              margin-top: 1.1875rem;
              .text_color {
                color: #ff6000;
              }
            }
            .word2 {
              width: 36.8125rem;
              font-size: .75rem;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #666666;
              line-height: 1.25rem;
              margin-top: 1.1875rem;
              .text_color {
                color: #ff6000;
              }
            }
          }
        }
        .textarea_box {
          width: 100%;
          height: 7.25rem;
          background: #f4f4f4;
          border: .0625rem solid #eaeaea;
          padding: .6875rem 1.75rem;
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 1.6875rem;
        }
        .input_box {
          width: 100%;
          height: 2.5rem;
          background: #f4f4f4;
          border: 1px solid #eaeaea;
          padding-left: 1.75rem;
          font-size: .875rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
      }
      .register_bom {
        display: flex;
        justify-content: center;
        .btn {
          width: 10rem;
          height: 2.75rem;
          background: #768288;
          font-size: 1.25rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #ffffff;
          border: none;
          text-align: center;
          line-height: 2.75rem;
        }
        .btn1 {
          background: #768288;
        }
        .btn2 {
          background: #2477e4;
          margin-left: 2.875rem;
        }
      }
    }
  }
  .bom {
    width: 100%;
    height: 310px;
    background: #f5f5f5;
  }
}
.failContent{
    margin: 65%;
    margin: 0 auto;
    width: 75rem;
    height: 9.375rem;
    background: #f0f6ff;
    border: .0625rem solid #2477e4;
    margin-top: 11.5rem;
    margin-bottom: 26.875rem;
    display: flex;
    padding-left: 3.25rem;
    padding-top: 1.875rem;
    .left3 {
      width: 2.875rem;
      height: 2.875rem;
      margin-right: 1.875rem;
    }
    .right3 {
      .title3 {
        font-size: 1.25rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
      }
      .btn3 {
        width: 6.25rem;
        height: 2.125rem;
        background: #2477e4;
        font-size: .875rem;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #ffffff;
        text-align: center;
        line-height: 2.125rem;
        margin-top: 1.25rem;
      }
    }
}
.drop_down ::v-deep .el-input--mini .el-input__inner {
  font-size: 1rem !important;
  padding: .9375rem 1.25rem !important;
}
.drop_down ::v-deep .el-select > .el-input {
  width: 25rem !important;
}
.drop_down ::v-deep .el-input--mini .el-input__inner {
  background: #eaeaea !important;
  padding: 1.0625rem .625rem !important;
}
.drop_down ::v-deep .el-date-editor.el-input {
  width: 100% !important;
}
.drop_down ::v-deep .el-input--mini .el-input__icon {
  display: none !important;
}
.drop_down ::v-deep .el-input--mini .el-input__inner {
  height: 2.5rem !important;
}
.drop_down2 ::v-deep .el-cascader {
  width: 100% !important;
}
.drop_down2 ::v-deep .el-cascader .el-input .el-input__inner {
  background: #f4f4f4 !important;
}
</style>
